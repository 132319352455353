import ApiService from './apiService';

import { LeadStatus } from '../models/leads/leadStatus';

class LeadStatusService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }

  getLeadStatuses(): Promise<Array<LeadStatus>> {
    return this.client.get('api/configuration/leads/status').then((response) => response.data);
  }

  createLeadStatus(leadStatus: LeadStatus): Promise<LeadStatus> {
    return this.client.post('api/configuration/leads/status', leadStatus).then((response) => response.data);
  }

  updateLeadStatus(leadStatus: LeadStatus): Promise<LeadStatus> {
    return this.client
      .patch(`api/configuration/leads/status/${leadStatus.leadStatusId}`, leadStatus)
      .then((response) => response.data);
  }

  deleteLeadStatus(leadStatusId: number): Promise<void> {
    return this.client.delete(`api/configuration/leads/status/${leadStatusId}`);
  }
}

export default LeadStatusService;
