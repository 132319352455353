import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import LeadService from '../../services/leadService';
import LeadSourceService from '../../services/leadSourceService';
import LeadStatusService from '../../services/leadStatusService';
import { GetDropdownTemplate, GetInputMaskTemplate, GetInputTextTemplate } from '../../utils/formTemplates';
import { Lead } from '../../models/leads/lead';
import { LeadSource } from '../../models/leads/leadSource';
import { LeadStatus } from '../../models/leads/leadStatus';

const LeadModal = (props: { lead: Lead; onSave: () => void; onHide: () => void }) => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [leadSources, setLeadSources] = useState<Array<LeadSource>>();
  const [leadStatuses, setLeadStatuses] = useState<Array<LeadStatus>>();
  const [isNew] = useState<boolean>(!props.lead.leadId);

  const leadService = new LeadService();
  const leadSourceService = new LeadSourceService();
  const leadStatusService = new LeadStatusService();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().trim(),
    lastName: Yup.string().required().trim(),
    primaryEmail: Yup.string().email().trim(),
    leadSourceId: Yup.number().required(),
    leadStatusId: Yup.number().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: props.lead,
    resolver: yupResolver(validationSchema),
  });

  useEffectOnce(() => {
    setLoading(true);
    Promise.all([leadSourceService.getLeadSources(), leadStatusService.getLeadStatuses()])
      .then((responses) => {
        setLeadSources(responses[0]);
        setLeadStatuses(responses[1]);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSave = (lead: Lead) => {
    setLoading(true);

    const addOrUpdate = isNew ? leadService.createLead(lead) : leadService.updateLead(lead);

    addOrUpdate
      .then(() => {
        props.onSave();
        props.onHide();
      })
      .catch((error: any) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={props.onHide} />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-primary p-button"
          onClick={handleSubmit(onSave)}
          loading={loading}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        visible={true}
        style={{ width: '75vw' }}
        header="Lead Details"
        modal
        footer={footerTemplate}
        onHide={props.onHide}
        className="p-fluid p-input-filled"
      >
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <Messages ref={errors} />
        <form>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              {GetInputTextTemplate(control, formErrors, 'firstName', 'First Name', {})}
            </div>
            <div className="field col-12 md:col-6">
              {GetInputTextTemplate(control, formErrors, 'lastName', 'Last Name', {})}
            </div>
            <div className="field col-12 md:col-6">
              {GetInputTextTemplate(control, formErrors, 'primaryEmail', 'Email', {})}
            </div>
            <div className="field col-12 md:col-6">
              {GetDropdownTemplate(control, formErrors, 'leadSourceId', 'Lead Source', leadSources, {
                optionLabel: 'name',
                optionValue: 'leadSourceId',
              })}
            </div>
            <div className="field col-12 md:col-6">
              {GetDropdownTemplate(control, formErrors, 'leadStatusId', 'Lead Status', leadStatuses, {
                optionLabel: 'name',
                optionValue: 'leadStatusId',
              })}
            </div>
            <div className="field col-12 md:col-6">
              {GetInputMaskTemplate(control, formErrors, 'homePhone', 'Home Phone', { mask: '(999) 999-9999' })}
            </div>
            <div className="field col-12 md:col-6">
              {GetInputMaskTemplate(control, formErrors, 'mobilePhone', 'Mobile Phone', { mask: '(999) 999-9999' })}
            </div>
          </div>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default LeadModal;
