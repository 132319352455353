import React from 'react';
import { Navigate } from 'react-router-dom';

const withAuthenticator = (WrappedComponent: (props?: any) => JSX.Element) => {
  const WithAuthenticator = (WrappedComponent: (props?: any) => JSX.Element) => {
    const token = localStorage.getItem('accessToken');

    if (!!token) {
      return (
        <React.Fragment>
          <WrappedComponent />
        </React.Fragment>
      );
    }

    return <Navigate to="/auth/login" />;
  };
  return WithAuthenticator(WrappedComponent);
};

export default withAuthenticator;
