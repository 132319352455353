import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';

import UserService from '../../services/userService';
import { User } from '../../models/auth/user';

const SignUpPage = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const userService = new UserService();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: new User(),
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required.'),
      lastName: Yup.string().required('Last name is required.'),
    }),
    onSubmit: (user) => {
      setLoading(true);
      userService
        .createUser(user)
        .then(() => {})
        .catch((error) => {
          showError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const getFormErrorMessage = (field: string) => {
    var meta = formik.getFieldMeta(field);
    return !!(meta.touched && meta.error) && <small className="p-error">{meta.error}</small>;
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  return (
    <>
      <div className="text-center text-900 text-3xl font-medium mb-5">Create an Account</div>

      <form className="p-fluid p-input-filled formgrid grid" onSubmit={formik.handleSubmit}>
        <div className="field col-12 mb-6">
          <label htmlFor="email">Email</label>
          <InputText id="email" type="text" {...formik.getFieldProps('email')} />
          {getFormErrorMessage('email')}
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="firstName">First Name</label>
          <InputText id="firstName" {...formik.getFieldProps('firstName')} />
          {getFormErrorMessage('firstName')}
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="lastName">Last Name</label>
          <InputText id="lastName" {...formik.getFieldProps('lastName')} />
          {getFormErrorMessage('lastName')}
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="homePhone">Home Phone</label>
          <InputMask
            id="homePhone"
            mask="(999) 999-9999"
            className="w-full mb-3"
            {...formik.getFieldProps('homePhone')}
          />
          {getFormErrorMessage('homePhone')}
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="mobilePhone">Mobile Phone</label>
          <InputMask
            id="mobilePhone"
            mask="(999) 999-9999"
            className="w-full mb-3"
            {...formik.getFieldProps('mobilePhone')}
          />
          {getFormErrorMessage('mobilePhone')}
        </div>

        <Button label="Submit" className="w-full mb-3" type="submit" loading={loading} />
        <Button
          label="Cancel"
          className="w-full p-button-secondary"
          onClick={() => navigate('../login')}
        />
      </form>
    </>
  );
};

export default SignUpPage;
