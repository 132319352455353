import ApiService from './apiService';

import { Lead } from '../models/leads/lead';

class LeadService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }

  getLeads(): Promise<Array<Lead>> {
    return this.client.get('api/leads').then((response) => response.data);
  }

  getLead(leadId: number): Promise<Lead> {
    return this.client.get(`api/leads/${leadId}`).then((response) => response.data);
  }

  createLead(lead: Lead): Promise<Lead> {
    return this.client.post('api/leads', lead).then((response) => response.data);
  }

  updateLead(lead: Lead): Promise<Lead> {
    return this.client.patch(`api/leads/${lead.leadId}`, lead).then((response) => response.data);
  }

  deleteLead(leadId: number): Promise<void> {
    return this.client.delete(`api/leads/${leadId}`);
  }
}

export default LeadService;
