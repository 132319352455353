import { Role } from './createUserRequest';

export class User {
  dateCreated?: Date;
  firstName: string = '';
  lastName: string = '';
  userName?: string = '';
  homePhone: string = '';
  mobilePhone: string = '';
  primaryEmailAddress: string = '';
  role?: Role;
  inactive: boolean = false;
}
