import './App.scss';

import { Navigate, Route, Routes } from 'react-router-dom';

import withAuthenticator from './components/auth/withAuthenticator';

import ErrorPage from './pages/ErrorPage';
import AppLayout from './components/app/AppLayout';
import AppAuthLayout from './components/app/AppAuthLayout';
import LoginPage from './pages/Auth/LoginPage';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/Auth/ResetPasswordPage';
import ConfirmUserPage from './pages/Auth/ConfirmUserPage';
import SignUpPage from './pages/Auth/SignUpPage';
import HomePage from './pages/HomePage';
import LeadsPage from './pages/Leads/LeadsPage';
import TenantsPage from './pages/TenantsPage';
import LeadSourcesPage from './pages/Leads/LeadSourcesPage';
import LeadStatusesPage from './pages/Leads/LeadStatusesPage';

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<Navigate to="/auth/login" />} />
        <Route element={<AppAuthLayout />}>
          <Route path="auth">
            <Route path="login" element={<LoginPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="confirm-user" element={<ConfirmUserPage />} />
          </Route>
        </Route>

        <Route element={<AppLayout />}>
          <Route path="home" element={withAuthenticator(HomePage)} />
          <Route path="leads" element={withAuthenticator(LeadsPage)} />
          <Route path="admin/lead-sources" element={withAuthenticator(LeadSourcesPage)} />
          <Route path="admin/lead-statuses" element={withAuthenticator(LeadStatusesPage)} />
          <Route path="admin/tenants" element={withAuthenticator(TenantsPage)} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default App;
