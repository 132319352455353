import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { classNames } from 'primereact/utils';

import AppMenu from './AppMenu';

const AppLayout = () => {
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const [menuActive, setMenuActive] = useState<boolean>(isDesktop());

  const onCollapseMenu = () => {
    if (!isDesktop()) {
      setMenuActive(false);
    }
  };

  return (
    <div
      className={classNames('layout-wrapper p-input-filled', {
        'layout-sidebar-inactive': !menuActive,
      })}
    >
      <div
        className={classNames('layout-overlay', {
          'layout-overlay-active': menuActive,
        })}
        onClick={onCollapseMenu}
      ></div>
      <AppMenu onCollapseMenu={onCollapseMenu} />
      <div className="layout-content-container flex flex-column justify-content-between">
        <div className="flex-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
