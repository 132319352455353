import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import UserService from '../../services/userService';
import { TokenRequest } from '../../models/auth/tokenRequest';

const LoginPage = () => {
  const errors = useRef<any>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const userService = new UserService();

  const formik = useFormik({
    initialValues: new TokenRequest(),
    validationSchema: Yup.object({
      username: Yup.string().required('Email is required.'),
      password: Yup.string().required('Password is required.'),
    }),
    onSubmit: (request) => {
      setLoading(true);
      userService
        .getToken(request)
        .then((response) => {
          localStorage.setItem('accessToken', response.token);
          navigate('/home');
        })
        .catch((error) => {
          showError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const getFormErrorMessage = (field: string) => {
    var meta = formik.getFieldMeta(field);
    return !!(meta.touched && meta.error) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <>
      <div className="text-center mb-5">
        <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
        <span className="text-600 font-medium line-height-3">Don't have an account?</span>
        <Link to="/auth/sign-up" className="font-medium no-underline ml-2 text-blue-500">
          Create today!
        </Link>
      </div>

      <form className="p-fluid p-input-filled formgrid grid" onSubmit={formik.handleSubmit}>
        <div className="field col-12">
          <label htmlFor="username">Email</label>
          <InputText id="username" type="text" {...formik.getFieldProps('username')} />
          {getFormErrorMessage('username')}
        </div>
        <div className="field col-12 mb-4">
          <label htmlFor="password">Password</label>
          <InputText id="password" type="password" {...formik.getFieldProps('password')} />
          {getFormErrorMessage('password')}
        </div>
        <Button
          label="Sign In"
          icon="pi pi-user"
          type="submit"
          loading={loading}
          className="mb-4 mx-2"
        />
        <div className="col-12 flex justify-content-center">
          <Link to="/auth/forgot-password" className="font-medium no-underline text-blue-500">
            Forgot Password?
          </Link>
        </div>
      </form>
    </>
  );
};

export default LoginPage;
