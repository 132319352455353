import ApiService from './apiService';

import { CreateUserRequest } from '../models/auth/createUserRequest';
import { User } from '../models/auth/user';
import { TokenRequest } from '../models/auth/tokenRequest';
import { TokenResponse } from '../models/auth/tokenResponse';
import { ResetPasswordRequest } from '../models/auth/resetPasswordRequest';
import { ForgotPasswordRequest } from '../models/auth/forgotPasswordRequest';

class UserService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }

  createUser(request: CreateUserRequest): Promise<User> {
    return this.client.post('api/users', request).then((response) => response.data);
  }

  getToken(request: TokenRequest): Promise<TokenResponse> {
    return this.client.post('api/users/token', request).then((response) => response.data);
  }

  sendConfirmationEmail(userId: number): Promise<void> {
    return this.client.post(`api/users/${userId}/confirmation-email`);
  }

  sendResetEmail(request: ForgotPasswordRequest): Promise<void> {
    return this.client.post('api/users/password/reset-email', request);
  }

  resetPassword(request: ResetPasswordRequest): Promise<void> {
    return this.client.post(`api/users/${request.userId}/password/reset`, request);
  }
}

export default UserService;
