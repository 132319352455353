export class Lead {
  leadId?: number;
  leadStatusId?: number;
  leadSourceId?: number;
  assignedUserId?: number;
  firstName?: string = '';
  lastName?: string = '';
  homePhone?: string;
  mobilePhone?: string;
  primaryEmail?: string;
  address?: string;
  birthDate?: Date;
  timezone?: string;
  flaggedDuplicate: boolean = false;
  contactMethod?: ContactMethod;
}

export enum ContactMethod {
  DoNotContact = 'DoNotContact',
  EmailOnly = 'EmailOnly',
  SMSOnly = 'SMSOnly',
  Both = 'Both',
}
