import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import UserService from '../../services/userService';
import { ResetPasswordRequest } from '../../models/auth/resetPasswordRequest';

const ResetPasswordPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const userService = new UserService();

  const formik = useFormik({
    initialValues: new ResetPasswordRequest(),
    validationSchema: Yup.object({
      password: Yup.string().required('Password is required.'),
      lastName: Yup.string().required('Last name is required.'),
    }),
    onSubmit: (request) => {
      setLoading(true);
      userService.resetPassword(request).finally(() => {
        setLoading(false);
      });
    },
  });

  const getFormErrorMessage = (field: string) => {
    var meta = formik.getFieldMeta(field);
    return !!(meta.touched && meta.error) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <>
      <div className="text-center text-900 text-3xl font-medium mb-5">Reset Password</div>

      <form className="p-fluid p-input-filled formgrid grid" onSubmit={formik.handleSubmit}>
        <div className="field col-12">
          <label htmlFor="password">Password</label>
          <InputText id="password" type="password" {...formik.getFieldProps('password')} />
          {getFormErrorMessage('password')}
        </div>

        <Button label="Submit" className="w-full" type="submit" loading={loading} />
      </form>
    </>
  );
};

export default ResetPasswordPage;
