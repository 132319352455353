import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';

import LeadSourceService from '../../services/leadSourceService';
import { GetInputTextTemplate } from '../../utils/formTemplates';
import { LeadSource } from '../../models/leads/leadSource';

const LeadSourceModal = (props: { leadSource: LeadSource; onSave: () => void; onHide: () => void }) => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNew] = useState<boolean>(!props.leadSource.leadSourceId);

  const leadSourceService = new LeadSourceService();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().trim(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: props.leadSource,
    resolver: yupResolver(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSave = (leadSource: LeadSource) => {
    setLoading(true);

    const addOrUpdate = isNew
      ? leadSourceService.createLeadSource(leadSource)
      : leadSourceService.updateLeadSource(leadSource);

    addOrUpdate
      .then(() => {
        props.onSave();
        props.onHide();
      })
      .catch((error: any) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={props.onHide} />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-primary p-button"
          onClick={handleSubmit(onSave)}
          loading={loading}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        visible={true}
        style={{ width: '50vw' }}
        header="Lead Source Details"
        modal
        footer={footerTemplate}
        onHide={props.onHide}
        className="p-fluid p-input-filled"
      >
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <Messages ref={errors} />
        <form>
          <div className="formgrid grid">
            <div className="field col-12">{GetInputTextTemplate(control, formErrors, 'name', 'Name', {})}</div>
          </div>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default LeadSourceModal;
