import { useRef, useState } from 'react';

import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import useEffectOnce from '../../hooks/useEffectOnce';
import LeadService from '../../services/leadService';
import LeadModal from '../../components/leads/LeadModal';
import { Lead } from '../../models/leads/lead';

const LeadsPage = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [leads, setLeads] = useState<Array<Lead>>([]);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [lead, setLead] = useState<Lead | null>(null);

  const leadService = new LeadService();

  useEffectOnce(() => {
    loadLeads();
  });

  const loadLeads = () => {
    setLoading(true);
    leadService
      .getLeads()
      .then((leads) => {
        setLeads(leads);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onGlobalFilterChange = (value: string) => {
    const _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
  };

  const onDelete = (lead: Lead) => {
    setLoading(true);
    leadService
      .deleteLead(lead.leadId!)
      .then(() => {
        loadLeads();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-row-reverse">
        <div>
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={filters['global'].value}
              onChange={(e) => onGlobalFilterChange(e.target.value)}
              placeholder="Search..."
            />
          </span>
          <Button label="New" icon="pi pi-plus" className="p-button-success" onClick={() => setLead(new Lead())} />
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (lead: Lead) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => setLead(lead)}
          tooltip="Edit Lead"
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() =>
            confirmDialog({
              message: `Are you sure you want to delete this lead: ${lead.firstName} ${lead.lastName}?`,
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => onDelete(lead),
            })
          }
          tooltip="Delete Lead"
        />
      </div>
    );
  };

  return (
    <Card title="Leads">
      <Messages ref={errors} />
      <ConfirmDialog />
      <DataTable
        value={leads}
        header={headerTemplate}
        responsiveLayout="scroll"
        loading={loading}
        filters={filters}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        stripedRows
      >
        <Column field="leadId" header="Lead Id" />
        <Column field="firstName" header="First Name" />
        <Column field="lastName" header="Last Name" />
        <Column field="homePhone" header="Home Phone" />
        <Column field="mobilePhone" header="Mobile Phone" />
        <Column field="primaryEmail" header="Email" />
        <Column header="Actions" body={actionBodyTemplate} />
      </DataTable>

      {lead != null && <LeadModal lead={lead} onSave={loadLeads} onHide={() => setLead(null)} />}
    </Card>
  );
};

export default LeadsPage;
