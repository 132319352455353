import React from 'react';
import { NavLink } from 'react-router-dom';

import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import { MenuItem } from '../../models/menuItem';

const AppMenuItem = (props: { item: MenuItem }) => {
  return (
    <div>
      {props.item.items && (
        <div className="menu-title">
          <span>{props.item.label}</span>
        </div>
      )}
      {/* <a>
        {props.item.icon && (
          <i className={classNames(props.item.icon, 'menu-item-icon', 'pi')}></i>
        )}
        <span>{props.item.label}</span>
        <i className="pi pi-chevron-down" />
      </a> */}
      <div className="menu-item">
        {!props.item.items && (
          <NavLink aria-label={props.item.label} to={props.item.to!} end className="flex align-items-center">
            {props.item.icon && <i className={classNames(props.item.icon, 'menu-item-icon', 'pi')} />}
            <span>{props.item.label}</span>
          </NavLink>
        )}
      </div>
    </div>
  );
};

const AppSubmenu = (props: { level: number; items: Array<MenuItem>; onCollapseMenu: () => void }) => {
  return (
    <div>
      <ul>
        {props.items.map((item, i) => {
          return (
            <React.Fragment key={item.label}>
              <li>
                <AppMenuItem item={item} />
                {item.items && (
                  <AppSubmenu level={props.level + 1} items={item.items} onCollapseMenu={props.onCollapseMenu} />
                )}
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

const AppMenu = (props: { onCollapseMenu: () => void }) => {
  const menu: Array<MenuItem> = [
    {
      icon: 'pi-users',
      label: 'Leads',
      to: '/leads',
    },
    {
      icon: 'pi-wrench',
      label: 'Admin',
      items: [
        {
          icon: 'pi-users',
          label: 'Lead Sources',
          to: '/admin/lead-sources',
        },
        {
          icon: 'pi-users',
          label: 'Lead Statuses',
          to: '/admin/lead-statuses',
        },
        {
          icon: 'pi-users',
          label: 'Tenants',
          to: '/admin/tenants',
        },
      ],
    },
  ];

  return (
    <div className="layout-sidebar">
      <div className="layout-sidebar-main-container">
        <AppSubmenu level={0} items={menu} onCollapseMenu={props.onCollapseMenu} />
      </div>
      {/* <div className="layout-sidebar-footer flex justify-content-center">
        <Button
          label="Sign Out"
          className="p-button p-button-text flex flex-auto"
          //onClick={signOut}
        />
      </div> */}
    </div>
  );
};

export default AppMenu;
