export enum SiteType {
  Trial = 'Trial',
  Pro = 'Pro',
  Corporate = 'Corporate',
  Enterprise = 'Enterprise',
}

export class Tenant {
  tenantId?: number;
  name?: string;
  siteType?: SiteType;
  url?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  inactive: boolean = false;
  appearanceSettings?: TenantAppearanceSettings = new TenantAppearanceSettings();
}

export class TenantAppearanceSettings {
  primaryColor?: string;
  secondaryColor?: string;
  logoFileId?: string;
  logoFileUrl?: string;
  useThemeColorsWhenLoggedIn: boolean = false;
}
