import ApiService from './apiService';

import { Tenant } from '../models/tenant';

class TenantService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }

  getTenants(): Promise<Array<Tenant>> {
    return this.client.get('api/tenants').then((response) => response.data);
  }

  createTenant(tenant: Tenant): Promise<Tenant> {
    return this.client.post('api/tenants', tenant).then((response) => response.data);
  }

  updateTenant(tenant: Tenant): Promise<Tenant> {
    return this.client.patch(`api/tenants/${tenant.tenantId}`, tenant).then((response) => response.data);
  }

  deleteTenant(tenantId: number): Promise<void> {
    return this.client.delete(`api/tenants/${tenantId}`);
  }
}

export default TenantService;
