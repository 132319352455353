import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';

import TenantService from '../services/tenantService';
import {
  GetColorPickerTemplate,
  GetDropdownTemplate,
  GetInputMaskTemplate,
  GetInputTextTemplate,
} from '../utils/formTemplates';
import { SiteType, Tenant } from '../models/tenant';
import { State } from '../models/state';

const TenantModal = (props: { tenant: Tenant; onSave: () => void; onHide: () => void }) => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNew] = useState<boolean>(!props.tenant.tenantId);

  const tenatService = new TenantService();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().trim(),
    url: Yup.string().required().trim(),
    appearanceSettings: Yup.object()
      .nullable()
      .shape({
        primaryColor: Yup.string().matches(/^[0-9A-F]{6}$/i, 'Primary color must be a valid hex'),
        secondaryColor: Yup.string().matches(/^[0-9A-F]{6}$/i, 'Secondary color must be a valid hex'),
      }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: props.tenant,
    resolver: yupResolver(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSave = (tenant: Tenant) => {
    setLoading(true);

    const addOrUpdate = isNew ? tenatService.createTenant(tenant) : tenatService.updateTenant(tenant);

    addOrUpdate
      .then(() => {
        props.onSave();
        props.onHide();
      })
      .catch((error: any) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={props.onHide} />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-primary p-button"
          onClick={handleSubmit(onSave)}
          loading={loading}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        visible={true}
        style={{ width: '75vw' }}
        header="Tenant Details"
        modal
        footer={footerTemplate}
        onHide={props.onHide}
        className="p-fluid p-input-filled"
        headerClassName="pb-2"
      >
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <Messages ref={errors} />
        <form>
          <Accordion activeIndex={0}>
            <AccordionTab header="Basic Info">
              <div className="formgrid grid">
                <div className="field col-12 md:col-6">
                  {GetDropdownTemplate(control, formErrors, 'siteType', 'Site Type', Object.keys(SiteType), {})}
                </div>
                <div className="field col-12 md:col-6">{GetInputTextTemplate(control, formErrors, 'name', 'Name', {})}</div>
                <div className="field col-12 md:col-6">{GetInputTextTemplate(control, formErrors, 'url', 'Url', {})}</div>
                <div className="field col-12 md:col-6">
                  {GetInputMaskTemplate(control, formErrors, 'phone', 'Phone', { mask: '(999) 999-9999' })}
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Address">
              <div className="formgrid grid">
                <div className="field col-12">
                  {GetInputTextTemplate(control, formErrors, 'address1', 'Address Line 1', {})}
                </div>
                <div className="field col-12">
                  {GetInputTextTemplate(control, formErrors, 'address2', 'Address Line 2', {})}
                </div>
                <div className="field col-12 md:col-4">{GetInputTextTemplate(control, formErrors, 'city', 'City', {})}</div>
                <div className="field col-12 md:col-4">
                  {GetDropdownTemplate(control, formErrors, 'state', 'State', Object.keys(State), {})}
                </div>
                <div className="field col-12 md:col-4">
                  {GetInputTextTemplate(control, formErrors, 'postalCode', 'Postal Code', {})}
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Appearance">
              <div className="formgrid grid">
                <div className="field col-12">
                  {GetDropdownTemplate(
                    control,
                    formErrors,
                    'appearanceSettings.useThemeColorsWhenLoggedIn',
                    'Use Theme Colors When Logged In',
                    [
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ],
                    {}
                  )}
                </div>
                <div className="field col-12 md:col-6">
                  {GetColorPickerTemplate(control, formErrors, 'appearanceSettings.primaryColor', 'Primary Color')}
                </div>
                <div className="field col-12 md:col-6">
                  {GetColorPickerTemplate(control, formErrors, 'appearanceSettings.secondaryColor', 'Secondary Color')}
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default TenantModal;
