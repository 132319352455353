import ApiService from './apiService';

import { LeadSource } from '../models/leads/leadSource';

class LeadSourceService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }

  getLeadSources(): Promise<Array<LeadSource>> {
    return this.client.get('api/configuration/leads/source').then((response) => response.data);
  }

  createLeadSource(leadSource: LeadSource): Promise<LeadSource> {
    return this.client.post('api/configuration/leads/source', leadSource).then((response) => response.data);
  }

  updateLeadSource(leadSource: LeadSource): Promise<LeadSource> {
    return this.client
      .patch(`api/configuration/leads/source/${leadSource.leadSourceId}`, leadSource)
      .then((response) => response.data);
  }

  deleteLeadSource(leadSourceId: number): Promise<void> {
    return this.client.delete(`api/configuration/leads/source/${leadSourceId}`);
  }
}

export default LeadSourceService;
