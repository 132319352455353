import { Outlet } from 'react-router-dom';

import { Card } from 'primereact/card';
import { ReactComponent as Logo } from '../../assets/media/logo.svg';

const AppAuthLayout = () => {
  return (
    <div className="h-screen flex align-items-center justify-content-center">
      <Card className="w-full md:w-5 h-full md:h-auto flex align-items-center justify-content-center auth-content-container">
        <div className="text-center">
          <Logo
            title="loanknow logo"
            className="w-8"
            style={{ maxWidth: '325px', height: '100px' }}
          />
        </div>
        <Outlet />
      </Card>
    </div>
  );
};

export default AppAuthLayout;
