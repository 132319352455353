import { useRef, useState } from 'react';

import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import useEffectOnce from '../../hooks/useEffectOnce';
import LeadSourceService from '../../services/leadSourceService';
import LeadSourceModal from '../../components/leads/LeadSourceModal';
import { LeadSource } from '../../models/leads/leadSource';

const LeadSourcesPage = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [leadSources, setLeadSources] = useState<Array<LeadSource>>([]);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [leadSource, setLeadSource] = useState<LeadSource | null>(null);

  const leadSourceService = new LeadSourceService();

  useEffectOnce(() => {
    loadLeadSources();
  });

  const loadLeadSources = () => {
    setLoading(true);
    leadSourceService
      .getLeadSources()
      .then((leadSources) => {
        setLeadSources(leadSources);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onGlobalFilterChange = (value: string) => {
    const _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
  };

  const onDelete = (leadSource: LeadSource) => {
    setLoading(true);
    leadSourceService
      .deleteLeadSource(leadSource.leadSourceId!)
      .then(() => {
        loadLeadSources();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-row-reverse">
        <div>
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={filters['global'].value}
              onChange={(e) => onGlobalFilterChange(e.target.value)}
              placeholder="Search..."
            />
          </span>
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success"
            onClick={() => setLeadSource(new LeadSource())}
          />
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (leadSource: LeadSource) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => setLeadSource(leadSource)}
          tooltip="Edit Lead Source"
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() =>
            confirmDialog({
              message: `Are you sure you want to delete this lead source: ${leadSource.name}?`,
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => onDelete(leadSource),
            })
          }
          tooltip="Delete Lead Source"
        />
      </div>
    );
  };

  return (
    <Card title="Lead Sources">
      <Messages ref={errors} />
      <ConfirmDialog />
      <DataTable
        value={leadSources}
        header={headerTemplate}
        responsiveLayout="scroll"
        loading={loading}
        filters={filters}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        stripedRows
      >
        <Column field="leadSourceId" header="Lead Source Id" />
        <Column field="name" header="Name" />
        <Column body={actionBodyTemplate} />
      </DataTable>

      {leadSource != null && (
        <LeadSourceModal leadSource={leadSource} onSave={loadLeadSources} onHide={() => setLeadSource(null)} />
      )}
    </Card>
  );
};

export default LeadSourcesPage;
