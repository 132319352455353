import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button } from 'primereact/button';

import UserService from '../../services/userService';
import { User } from '../../models/auth/user';

const ConfirmUserPage = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const userService = new UserService();

  const formik = useFormik({
    initialValues: new User(),
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required.'),
      password: Yup.string().required('Password is required.'),
    }),
    onSubmit: (request) => {
      setLoading(true);
      userService
        .createUser(request)
        .then(() => {})
        .catch((error) => {
          showError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const getFormErrorMessage = (field: string) => {
    var meta = formik.getFieldMeta(field);
    return !!meta.error && <small className="p-error">{meta.error}</small>;
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Button
          label="Sign In"
          icon="pi pi-user"
          className="w-full"
          type="submit"
          loading={loading}
        />
      </form>
    </>
  );
};

export default ConfirmUserPage;
